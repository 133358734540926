.search-container {
    position: sticky;
    top: 0;
    /* width: 100%; */
    max-width: 500px;
    margin: 0 auto;
    z-index: 1000;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.search-bar {
    flex-grow: 1;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: #f1f1f1;
    padding: 10px;
    transition: all 0.3s ease-in-out;
}

.search-input {
    flex-grow: 1;
    border: none;
    background: none;
    outline: none;
    padding: 0 10px;
    font-size: 16px;
}

.logo, .chat-icon, .flag-icon {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 50%;
}

.full-screen .search-bar {
    position: fixed;
    display: block;
    width: 100%;
    max-width: 453px;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: none;
    border-radius: 0;
}

.full-screen .search-input {
    font-size: 20px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

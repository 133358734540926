.withdrawal-form {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.withdrawal-form h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}

.currency-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.currency-icon img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.currency-details {
    display: flex;
    flex-direction: column;
}

.available-balance {
    background-color: #f0f8ff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.amount-input {
    margin-bottom: 20px;
}

.amount-input label {
    font-weight: bold;
}

.amount-input input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1.2em;
}

.btc-equivalent {
    display: block;
    margin-top: 10px;
    color: #888;
}

.charges-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.charges-info .charges {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: bold;
}

.charges-info p {
    font-size: 0.9em;
    color: #555;
}

.submit-button {
    text-align: center;
}

.submit-button button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
}

.submit-button button:hover {
    background-color: #0056b3;
}

.market-order {
  font-family: Arial, sans-serif;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden; /* Ensure no overflow on main container */
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: #007bff;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
}

.tab.active {
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto; /* Enable vertical scrolling */
  flex: 1; /* Allow the table to grow and take available space */
}

.table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
}

.table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.table-row:hover {
  background-color: #f1f1f1;
}

.product {
  display: flex;
  align-items: center;
}

.product img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.last-price, .today-change {
  display: inline-block;
  min-width: 80px;
  text-align: right;
}

.today-change.positive {
  color: green;
}

.today-change.negative {
  color: red;
}

.selected-symbol {
  margin-top: 20px;
  text-align: center;
}

.selected-symbol h3 {
  margin: 0;
  margin-bottom: 10px;
}

.fullscreen {
  position: relative;
  height: 100vh; /* Ensure fullscreen view takes up full viewport */
  overflow: hidden; /* Hide overflow in fullscreen mode */
}

.fullscreen-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #131F2F;
  z-index: 1000;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  overflow: hidden; /* Hide overflow in fullscreen mode */
}

.fullscreen-overlay .back-button {
  position: absolute;
  top: 13px;
  left: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.chart-container {
  width: 100%;
  max-width: 1200px; /* Set the max width of the chart */
}

.chart-title {
  color: #fff;
  font-size: 18px;
  text-align: center;
}

#tradingview_chart {
  width: 99.9%;
  height: 100%; /* Make sure chart takes full height of container */
  margin-top: 40px;
}

.trading-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: absolute; /* Position buttons absolutely within the container */
  bottom: 60px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  z-index: 1100; /* Ensure buttons are on top */
  width: 100%;
}
.buy-up-button, .buy-down-button {
  padding: 15px 20px;
  margin: 0 10px; /* Adjust the space between buttons */
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 45%; /* Ensure buttons have equal width */
  text-align: center;
  font-weight: bold;
}

.buy-up-button {
  background-color: #00c087; /* Green color matching the "Buy Up" button */
}

.buy-down-button {
  background-color: #f6465d; /* Red color matching the "Buy Down" button */
}

.login-container {
  max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.auth-links {
  display: flex;
  justify-content: space-between; /* Adjusts the spacing between the links */
  align-items: center; /* Vertically centers the links */
}

.forgot-password-link,
.signup-link {
  margin: 0 10px; /* Adjusts the spacing around each link */
  text-decoration: none; /* Removes the underline */
  color: #007bff; /* Example link color */
}

.forgot-password-link:hover,
.signup-link:hover {
  text-decoration: underline; /* Adds underline on hover */
}


.login-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.signup-link {
  color: #007bff;
  text-decoration: none;
}

.login-title {
  text-align: center;
  margin: 20px 0;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.input-group input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.toggle-password {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 35px;
  cursor: pointer;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #0056e0;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.forgot-password-link {
  display: block;
  text-align: right;
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
}

.user-agreement {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.user-agreement-link {
  color: #007bff;
  text-decoration: none;
  margin-left: 5px;
}

.error-message {
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  border: 1px solid #f5c6cb; /* Light red border */
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.change-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .change-password-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .change-password-form {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  .btn-change-password {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn-change-password:hover {
    background-color: #0056b3;
  }
  
  .extra-links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
  }
  
  .extra-links a {
    color: #007bff;
    font-size: 14px;
    text-decoration: none;
  }
  
  .extra-links a:hover {
    text-decoration: underline;
  }
  
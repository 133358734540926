.service-center {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
  
  .service-center h2 {
    color: #fff;
    background-color: #0057ff;
    padding: 10px;
    border-radius: 10px 10px 0 0;
  }
  
  .service-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #eee;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .service-item:first-child {
    border-top: none;
  }
  
  .service-item:hover {
    background: #f9f9f9;
  }
  
  .icon {
    font-size: 24px;
    color: #000;
    /* margin-right: 15px; */
  }
  
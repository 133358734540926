.receipt-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .instructions {
    background: #fff7e6;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #ff9800;
  }
  
  .deposit-amount {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input[type="number"],
  .upload-label {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .upload-certificate {
    margin-bottom: 20px;
  }
  
  .upload-input {
    display: none;
  }
  
  .upload-label {
    display: block;
    padding: 20px;
    background: #f7f7f7;
    border: 2px dashed #ddd;
    cursor: pointer;
    color: #999;
  }
  
  .confirm-button {
    display: block;
    width: 100%;
    padding: 10px;
    background: #0057ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
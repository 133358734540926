.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .not-found-content {
    max-width: 600px;
    padding: 20px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .not-found-content h1 {
    font-size: 96px;
    color: #ff4757;
    margin-bottom: 20px;
  }
  
  .not-found-content h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  
  .not-found-content p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #555;
  }
  
  .not-found-content button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #ff4757;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .not-found-content button:hover {
    background-color: #ff6b81;
  }
  
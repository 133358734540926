.order-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background-color: #1a1a2e;
  color: #ffffff;
  border-radius: 10px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-icon {
  cursor: pointer;
  font-size: 24px;
  color: #ffffff;
}

.title {
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
}

.order-details {
  padding: 10px 0;
}

.order-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.order-row span {
  font-size: 16px;
}

.order-duration {
  margin: 20px 0;
}

.duration-options {
  display: flex;
  justify-content: space-between;
}

.duration-option {
  flex: 1;
  background-color: #24243e;
  padding: 15px;
  margin: 0 5px;
  text-align: left; /* Align text to the left */
  border-radius: 10px;
  cursor: pointer;
}

.duration-option.selected {
  border: 2px solid #3b82f6;
}

.duration-option span {
  display: block;
  margin-bottom: 5px; /* Add space between lines */
}

.duration-time {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
}

.duration-profit {
  font-size: 16px;
  color: #ff6347;
}


.purchase-amount {
  margin: 20px 0;
}

.purchase-amount input {
  width: 100%;
  padding: 10px;
  background-color: #24243e;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #3b82f6;
  font-size: 18px;
}

.amount-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.amount-buttons button {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  border-radius: 10px;
  background-color: #3b82f6;
  color: white;
  cursor: pointer;
  border: none;
}

.amount-info {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #e0e0e0;
}

.confirm-button {
  width: 100%;
  padding: 15px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}

.confirm-button:disabled {
  background-color:gray;
}

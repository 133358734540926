.forgot-password-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forgot-password-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.back-button {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.forgot-password-title {
  text-align: center;
  margin: 20px 0;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.input-group input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.request-code-button {
  width: 100%;
  padding: 10px;
  background-color: #0056e0;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.error-message,
.success-message {
  text-align: center;
  color: #d9534f; /* Error color */
  font-size: 0.9rem;
}

.success-message {
  color: #5bc0de; /* Success color */
}

.timer-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.timer-content {
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
    color: #fff;
    position: relative;
}

.timer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.timer-header h2 {
    font-size: 18px;
}

.close-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
}

.timer-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.timer-svg {
    width: 150px;
    height: 150px;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.4s ease, stroke 0.4s ease;
}

.timer-text {
    fill: #fff;
    font-size: 0.5em;
    text-anchor: middle;
}

.timer-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.btn-close {
    background-color: #555;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btn-continue {
    background-color: #1e88e5;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

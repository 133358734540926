.forgot-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .forgot-password-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .back-button {
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .forgot-password-title {
    text-align: center;
    margin: 20px 0;
  }
  .country-option {
    display: flex;
    align-items: center;
  }
  
  .country-flag {
    width: 24px;
    height: 16px;
    margin-right: 8px;
  }
  
  
  .tab-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-switch button {
    border: none;
    background: none;
    font-size: 1rem;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .tab-switch .active {
    border-bottom: 2px solid #0056e0;
    font-weight: bold;
  }
  
  .forgot-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  
  .input-group input,
  .input-group select {
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .phone-input {
    display: flex;
  }
  
  .code-input {
    width: 30%;
    margin-right: 5px;
  }
  
  .request-code-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .modify-button {
    width: 100%;
    padding: 10px;
    background-color: #0056e0;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  .error {
    color: red;
    font-size: 0.85rem;
  }
.bottom-nav {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;  /* Match the width with the rest of the page */
    background-color: #fff;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;  /* Include padding and border in total width */
}



.bottom-nav a {
    text-decoration: none;
    color: #9BA1AE;
    text-align: center;
    flex: 1;
}
.bottom-nav a svg{
    height: 2rem;
    width: 2rem;
}

.bottom-nav a span {
    display: block;
    font-size: 12px;
}

.bottom-nav a.active {
    color: #007bff;
}

.bitcoin-price-container {
    font-family: Arial, sans-serif;
    margin: 20px 0;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .bitcoin-icon {
    width: 20px;
    vertical-align: middle;
    margin-right: 8px;
  }
  
  .negative {
    color: red;
  }
  
  .positive {
    color: green;
  }
  
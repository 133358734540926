/* Order.css */


.heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.tab-button {
    padding: 10px 20px;
    border: none;
    background: transparent;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
}

.tab-button.active {
    background: #007bff;
    color: #fff;
}

.content {
    flex-grow: 1; /* Allows content area to grow and take up remaining space */
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-records {
    text-align: center;
    color: #999;
    font-size: 16px;
}

.no-records img {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}

.dashboard-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s;
  background-color: transparent;
  text-decoration: none;
}

.icon:hover {
  transform: scale(1.05);
}

.icon-svg {
  font-size: 28px;
  color: #2e2e2e;
}

.icon span {
  margin-top: 6px;
  font-size: 12px;
  color: #555;
}

.badge {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ffcc00;
  color: #fff;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 5px;
}

.badge.hot {
  background-color: #ff4d4d;
}
.icon:hover span {
  text-decoration: none;
}

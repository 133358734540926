.deposit-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .currency-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .currency {
    display: flex;
    align-items: center;
  }
  
  .currency-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .chain {
    color: #666;
  }
  
  .recharge-addr,
  .contact-service,
  .number-of-deposits {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .chat-button {
    display: block;
    width: 100%;
    padding: 10px;
    background: #0057ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .amount {
    margin-top: 10px;
    color: #666;
  }
  
  .warning p {
    font-size: 12px;
    color: #999;
  }
  
  .recharge-button {
    display: block;
    width: 100%;
    padding: 10px;
    background: #0057ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
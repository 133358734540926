.result-page {
    text-align: center;
    padding: 20px;
}

.result-message {
    font-size: 18px;
    margin-top: 20px;
}

h1 {
    color: #333;
}

p {
    font-size: 16px;
    color: #555;
}

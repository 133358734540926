.digital-currency-container {
  padding: 16px;
  width: 100%;
  max-width: 500px;
  margin: auto;
}




.digital-currency-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.digital-currency-back-button {
  background: none;
  border: none;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.digital-currency-title {
  font-size: 24px;
  font-weight: 700;
  color: #2c2f36;
  margin: 0;
}

.digital-currency-subheader {
  font-size: 14px;
  color: #8b909a;
  margin-bottom: 20px;
}

.digital-currency-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.digital-currency-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f7f8fa;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.digital-currency-detail:hover {
  background-color: #eaeef4;
}

.digital-currency-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.digital-currency-detail-info {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coin-name {
  font-size: 16px;
  font-weight: 600;
  color: #2c2f36;
}

.coin-chain {
  font-size: 14px;
  color: #8b909a;
}

.arrow-icon {
  font-size: 20px;
  color: #8b909a;
}

.bind-pocket-address {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .warning-text {
    color: #ff6f00;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .currency-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .currency-details {
    display: flex;
    align-items: center;
  }
  
  .currency-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .currency-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .chain-name {
    color: #888;
    font-size: 16px;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .input-label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .pocket-addr-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .submit-button {
    width: 100%;
    padding: 15px;
    background-color: #3366ff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #254edb;
  }
  
/* Sliders.css */
.slider-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

}

.slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 200px;

}

.slide img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    height: 200px;

}

.slider-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;
}

.slider-text h2 {
    font-size: 24px;
    margin: 0;
}

.slider-text p {
    font-size: 16px;
}
.slick-dots{
    bottom: 25px !important;
}

.slick-dots li.slick-active button:before{
    color: #fff;
}
.featured-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
  }
  
  .featured-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .featured-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .featured-icon {
    width: 40px;
    height: 40px;
  }
  
  .featured-text h3 {
    font-size: 16px;
    margin: 0;
    color: #333;
  }
  
  .featured-text p {
    font-size: 12px;
    margin: 0;
    color: #888;
  }
  
  .featured-arrow-button {
    background-color: #2e7dff; /* Blue background similar to the example */
    border-radius: 50%;
    width: 32px; /* Adjust to make it a perfect circle */
    height: 32px; /* Same as width for a circular shape */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* White arrow color */
  }
  
  .featured-more {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #888;
  }
  
  .featured-more span {
    font-size: 12px;
  }
  
  .featured-right svg {
    font-size: 16px; /* Adjust arrow icon size if needed */
  }
  
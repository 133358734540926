.chat-container {
  max-width: 600px;
  margin: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  padding: 10px;
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  font-weight: bold;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-message {
  padding: 8px 24px;
  margin: 5px 0;
  border-radius: 20px;
  max-width: 75%;
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
}

.user-message {
  background-color: #DCF8C6;
  color: #000;
  align-self: flex-end;
}

.admin-message {
  background-color: #FFF;
  color: #000;
  align-self: flex-start;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.chat-time {
  position: absolute;
  bottom: -16px;
  right: 8px;
  font-size: 10px;
  color: #555;
}

.chat-footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  background-color: #fff;
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  margin-right: 10px;
  outline: none;
}

.chat-footer button {
  padding: 10px 20px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

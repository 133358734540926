.balance-page {
  text-align: center;
}
.balance-header{
  background-color: #2351BD;
  color: #fff;
  padding: 10px;
}
.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
}

.action-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  background-color: lightgray;
  cursor: pointer;
  border-radius: 12px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background-color: lightgray;
  cursor: pointer;
}

.tabs button.active {
  background-color: darkgray;
}

.tab-content {
  margin-top: 20px;
}

.balance-display {
  margin: 20px;
}

.balance-display div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-display button {
  background: none;
  border: none;
  cursor: pointer;
}

.pocket-addr-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    align-items: center;
  }
  
  .back-button {
    font-size: 24px;
    margin-right: 20px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .wallet-list {
    margin-top: 20px;
  }
  
  .wallet-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .coin-logo {
    width: 40px;
    height: 40px;
  }
  
  .wallet-info {
    flex-grow: 1;
    margin-left: 10px;
  }
  
  .coin-name {
    font-weight: bold;
  }
  
  .wallet-address {
    color: #888;
    margin-top: 5px;
  }
  
  .chain-name {
    color: #0066cc;
    margin-right: 10px;
  }
  
  .more-options-icon {
    font-size: 20px;
    cursor: pointer;
  }
  
  .available-coins {
    margin-top: 30px;
  }
  
  .coin-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .coin-item .coin-name {
    margin-left: 10px;
    flex-grow: 1;
  }
  
  .add-icon {
    font-size: 20px;
    cursor: pointer;
    color: #0066cc;
  }
  
/* Sliders.css */
html, body {
    overflow: hidden;  /* Prevent scrolling on the entire viewport */
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.Home-container {
    max-width: 500px;  /* Constrain the content to 500px */
    margin: 0 auto;    /* Center the content */
    overflow-y: auto;
    overflow-x: hidden;    /* Enable scrolling within this container */
    height: 100vh;     /* Ensure it takes full viewport height for scrolling */
    box-sizing: border-box;
    

}
/* General styles for the content container */


/* WebKit browsers */
.Home-container::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

.Home-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background color */
}

.Home-container::-webkit-scrollbar-thumb {
    background: #888; /* Darker color for the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
}

.Home-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Even darker color on hover */
}


.deposit-method-container {
    width: 100%;
    max-width: 462px;
    margin: auto;
    height: 100vh;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .deposit-method-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  
  .deposit-method-back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .deposit-method-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .deposit-method-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #eee;
  }
  
  .deposit-method-icon-container {
    margin-right: 10px;
    margin-top: 7px;
  
  }
  
  .deposit-method-icon {
    width: 24px;
    height: 24px;
  }
  
  .deposit-method-item-text {
    flex-grow: 1;
    font-size: 18px;
  }
  
  .deposit-method-arrow-container {
    font-size: 18px;
  }
  

.navigation-menu {
    max-width: 500px;
    width: 100%;  
    margin: 0 auto;    
    overflow-y: auto;
    overflow-x: hidden;    
    box-sizing: border-box;
    background-color: #fff;
    border-right: 1px solid #ddd;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    transform: translateX(-50%);
    z-index: 1001;

}
.navigation-menu.open {
    transform: translateY(0) translateX(-50%);
}

.navigation-menu.closed {
    transform: translateY(-100%) translateX(-50%);
}

.profile-section {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.profile-info {
    display: flex;
    flex-direction: column;
}

.profile-name {
    font-weight: bold;
    font-size: 16px;
}

.profile-welcome {
    font-size: 12px;
    color: #888;
}

.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-list li {
    border-bottom: 1px solid #e0e0e0;
}

.menu-list li a {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
}

.menu-list li a:hover {
    background-color: #f5f5f5;
}

.menu-list li a .menu-icon {
    margin-right: 10px;
    color: #666;
}
.close-menu-btn{
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
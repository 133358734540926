.container {
    max-width: 400px;
    margin: auto;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-icon {
    cursor: pointer;
    margin-right: 10px;
    font-size: 24px;
  }
  
  .title {
    font-size: 24px;
    margin: 0;
    flex-grow: 1;
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #e0e0e0;
  }
  
  .logout-button {
    width: 100%;
    padding: 10px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  